<template>
  <div id="dashboard-analytics">
    <vx-card>
      <!-- levels data start -->

      <div class="vx-row mx-0">
        <div class="px-5 py-5">
          <ul class="activity-timeline" v-if="UserLevel.length > 0">
            <li
              class
              v-for="(level, levelIndex) in UserLevel"
              :key="levelIndex"
            >
              <div class="timeline-icon bg-success">
                <span class="font-bold text-white"
                  >Level {{ level.contract_level }}</span
                >
              </div>

              <div class="timeline-info">
                <!-- <p class="font-semibold">Approved</p> -->
                <ul class="list-inline mb-0 d-flex flex-wrap">
                  <li
                    class="list-inline-item mb-3 ml-3"
                    v-for="(singleUser, userIndex) in level.user_names"
                    :key="'user' + userIndex"
                  >
                    <div class="po-user-card px-3 rounded">
                      <!-- <i class="text-center material-icons level-close-icon"
                        >close</i
                      > -->
                      <h6 class="font-semibold mb-0 text-center">
                        {{ singleUser }}
                      </h6>
                    </div>
                  </li>
                  <!-- <li class="list-inline-item mb-0 ml-3">
                    <div class="add-user-btn px-3 rounded">
                      <h6 class="font-semibold mb-0 text-center">
                        <span class="pluse_1"></span>
                        <i class="text-center material-icons">add_circle</i>
                      </h6>
                    </div>
                  </li> -->
                </ul>
              </div>
              <!-- <small class="text-grey activity-e-time">25 mins ago</small> -->
            </li>
            <!-- <li class="mb-base pb-5">
              <div class="add-level-icon">
                <span class="font-bold text-white">
                  <i class="text-center material-icons align-middle"
                    >add_circle</i
                  >
                </span>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="vx-row mt-5" v-if="UserLevel.length == 0">
        <h4 class="ml-5">No User Level</h4>
      </div>
    </vx-card>
  </div>
</template>

<script>
// import addLevel from "@/views/apps/projects/addLevel.vue";
// import levelOverView from "@/views/apps/projects/levelOverView.vue";
// import removeLevel from "@/views/apps/projects/removeLevel.vue";
// import _ from "lodash";
// import projectService from "@/services/projectService.js";
import ContractService from "@/services/ContractService.js";
import ProjectCard from "@/components/project-cards/projectCard.vue";
// import CompanyService from "@/services/companyService.js";
// import axios from "axios";
// import { BASEURL } from "@/config/index";

export default {
  data() {
    return {
      UserLevel: []
    };
  },
  components: {
    ProjectCard
  },
  beforeMount() {
    this.getLevel();
  },
  methods: {
    getLevel: function() {
      this.$vs.loading();
      ContractService.getAllContractDataLevel()
        .then(response => {
          const { data } = response;
          if (!data.err) {
            this.$vs.loading.close();
            if (data.hasOwnProperty("data")) {
              this.UserLevel = data.data;
            }

            //eventBus.$emit("categoryListShow", data.data);

            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  components: {}
};
</script>

<style lang="scss"></style>
